import { PageName } from "~/utils/enum/page_name";

export default defineNuxtRouteMiddleware((to) => {
  // Предохранитель от цикла
  if (to?.name === PageName.Home.toString()) {
    return;
  }

  const authStore = useAuthStore();

  if (!authStore.getIsSuccessfullyUpdatedUserSession) {
    return navigateTo({
      name: PageName.Home.toString(),
    });
  }
});
